body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Inter font */
@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Baskerville';
  src: url(./assets/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Baskerville Italic';
  src: local('Baskerville italic'),
    url(./assets/fonts/Libre_Baskerville/LibreBaskerville-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Opens Sans';
  src: local('Opens Sans'), url(./assets/fonts/Opens_Sans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Opens Sans Bold';
  src: local('Opens Sans'), url(./assets/fonts/Opens_Sans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Opens Sans SemiBold';
  src: local('Opens Sans'), url(./assets/fonts/Opens_Sans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Opens Sans Italic';
  src: local('Opens Sans'), url(./assets/fonts/Opens_Sans/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Opens Sans Medium';
  src: local('Open Sans'), url(./assets/fonts/Opens_Sans/OpenSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Opens Sans Light';
  src: local('Open Sans'), url(./assets/fonts/Opens_Sans/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Shape';
  src: local('Shape'), url(./assets/fonts/Shape/Shape-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Shape Bold';
  src: local('Shape'), url(./assets/fonts/Shape/Shape-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Shape SemiBold';
  src: local('Shape'), url(./assets/fonts/Shape/Shape-SemiBold.otf) format('truetype');
}

@font-face {
  font-family: 'Shape Italic';
  src: local('Shape'), url(./assets/fonts/Shape/Shape-RegularItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Shape Medium';
  src: local('Shape'), url(./assets/fonts/Shape/Shape-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'Shape Light';
  src: local('Shape'), url(./assets/fonts/Shape/Shape-Light.otf) format('truetype');
}

/* Leaflet Custom css */
.leaflet-marker-icon {
  height: 0 !important;
  width: 0 !important;
  border: none !important;
}
